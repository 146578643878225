import { scrollbarShow, scrollbarHidden } from './utils'

export const openSidebar = (sidebar: HTMLElement): void => {
  scrollbarHidden()
  sidebar.dataset.sidebar = 'open'
}

export const closeSidebar = (sidebar: HTMLElement): void => {
  scrollbarShow()
  sidebar.dataset.sidebar = 'close'
}

export default (): void => {
  document.addEventListener('click', ((event: Event): void => {
    if ((event.target as HTMLButtonElement).closest('[data-sidebar-open]')) {
      const open = event.target as HTMLButtonElement
      const sidebar = document.querySelector(
        `#${open.dataset.sidebarOpen}`
      ) as HTMLDivElement

      if (sidebar) openSidebar(sidebar)
    }

    if ((event.target as HTMLButtonElement).closest('[data-sidebar-close]')) {
      const close = event.target as HTMLButtonElement
      const sidebar = document.querySelector(
        `#${close.dataset.sidebarClose}`
      ) as HTMLDivElement

      if (sidebar) closeSidebar(sidebar)
    }

    if ((event.target as HTMLDivElement).hasAttribute('data-sidebar')) {
      const sidebar = event.target as HTMLDivElement

      if (sidebar) closeSidebar(sidebar)
    }
  }) as EventListener)
}
