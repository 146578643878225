import fancybox from './ts/fancybox'
import sliderSwiper from './ts/slider-swiper'
import scrollHeader from './ts/scroll-header'
import currentTab from './ts/current-tab'
import sidebar from './ts/sidebar'
import scrollTo from './ts/scroll-to'
import pallete from './ts/pallete'
import animation from './ts/animation'
import waved from './ts/waved'
import listing from './ts/listing'
import filtering from './ts/filtering'
import downloadFiles from './ts/download-files'
import submitHandler from './ts/submit-handler'
import inputs from './ts/inputs'
import reset from './ts/reset'
import phoneMask from './ts/phone-mask'
import quiz from './ts/quiz'
import videoRemove from './ts/video-remove'
import preloader from './ts/preloader'
import './scss/main.scss'

window.addEventListener('DOMContentLoaded', ((): void => {
  fancybox()
  sliderSwiper()
  currentTab()
  scrollHeader()
  sidebar()
  scrollTo()
  pallete()
  animation()
  waved()
  listing()
  filtering()
  downloadFiles()
  submitHandler()
  inputs()
  reset()
  phoneMask()
  quiz()
  videoRemove()
  preloader()
}) as EventListener)
