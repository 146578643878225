import { fileHandler } from './utils'

export default () => {
  document.addEventListener('change', ((event: Event): void => {
    if (
      (event.target as HTMLInputElement).getAttribute('data-input') === 'file'
    ) {
      const input = event.target as HTMLInputElement
      const quiz = input.closest('[data-quiz]') as HTMLDivElement

      if (!quiz) return

      const download = quiz.querySelector(
        '*[data-label="download"]'
      ) as HTMLDivElement
      const error = download.querySelector('*[data-error]') as HTMLSpanElement
      const files = quiz.querySelector('*[data-files]') as HTMLDivElement
      const text = files.querySelector('*[data-files-text]') as HTMLSpanElement

      if (fileHandler({ input, error })) {
        const file = (input.files as FileList)[0] as File

        quiz.dataset.quiz = 'auto'
        download.classList.add('pointer-events-none', 'opacity-50')
        files.classList.remove('invisible')
        files.classList.add('flex')
        text.innerText = file.name
      } else {
        quiz.dataset.quiz = 'stop'
        input.value = ''
      }
    }
  }) as EventListener)

  document.addEventListener('click', ((event: Event): void => {
    if ((event.target as HTMLButtonElement).closest('[data-files-remove]')) {
      const remove = event.target as HTMLButtonElement
      const quiz = remove.closest('[data-quiz]') as HTMLDivElement

      if (!quiz) return

      const download = quiz.querySelector(
        '*[data-label="download"]'
      ) as HTMLDivElement
      const input = download.querySelector(
        '*[data-input="file"]'
      ) as HTMLInputElement
      const files = quiz.querySelector('*[data-files]') as HTMLDivElement
      const text = files.querySelector('*[data-files-text]') as HTMLSpanElement

      quiz.dataset.quiz = 'stop'
      download.classList.remove('pointer-events-none', 'opacity-50')
      files.classList.add('invisible')
      input.value = ''
      text.innerText = ''
    }
  }) as EventListener)
}
