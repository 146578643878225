type Input = HTMLInputElement | HTMLTextAreaElement

const inputsReser = (event: Event): void => {
  if ((event.target as HTMLInputElement).hasAttribute('data-reset-toggle')) {
    const toggle = event.target as HTMLInputElement
    const reset = toggle.closest('[data-reset]') as HTMLFormElement

    if (!reset) return

    const inputs = reset.querySelectorAll(
      '*[data-reset-input]'
    ) as NodeListOf<Input>
    const download = reset.querySelector(
      '*[data-label="download"]'
    ) as HTMLDivElement
    const files = reset.querySelector('*[data-files]') as HTMLDivElement
    const text = reset.querySelector('*[data-files-text]') as HTMLSpanElement

    inputs.forEach((input: Input): void => {
      if (!input || input.dataset.resetInput === toggle.dataset.resetToggle)
        return

      input.value = ''

      if (input.dataset.resetInput !== 'files') {
        download.classList.remove('pointer-events-none', 'opacity-50')
        files.classList.add('invisible')
        text.innerText = ''
      }
    })
  }
}

export default (): void => {
  document.addEventListener('input', inputsReser as EventListener)
}
