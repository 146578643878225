import { touchDevice } from './utils'

export default (): void => {
  if (!touchDevice()) return

  const videos = document.querySelectorAll(
    'video'
  ) as NodeListOf<HTMLVideoElement>

  videos.forEach((video: HTMLVideoElement): void => {
    if (!video) return

    video.pause()
    video.removeAttribute('src')
    video.load()
    video.remove()
  })
}
