import Swiper from 'swiper'
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Grid,
  Thumbs,
  EffectFade,
} from 'swiper/modules'
import { media } from './utils'
import { checkQuizSlide } from './quiz'

declare global {
  interface Window {
    Swiper: typeof Swiper
  }
}

Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Grid,
  Thumbs,
  EffectFade,
])
Swiper.defaults.touchStartPreventDefault = false
window.Swiper = Swiper

const createCompanySlider = (): void => {
  const slider = document.querySelector(
    '*[data-slider="company"]'
  ) as HTMLDivElement

  if (!slider) return

  const swiper = slider.querySelector('.swiper') as HTMLDivElement

  new window.Swiper(swiper, {
    slidesPerView: 1.2,
    slidesPerGroup: 1,
    spaceBetween: 16,
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      [media.lg]: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      [media.xl]: {
        slidesPerView: 4,
        spaceBetween: 28,
      },
    },
  }) as Swiper
}

const createProjectSlider = (): void => {
  const sliders = document.querySelectorAll(
    '*[data-slider="project"]'
  ) as NodeListOf<HTMLDivElement>

  sliders.forEach((slider: HTMLDivElement): void => {
    if (!slider) return

    const swiper = slider.querySelector('.swiper') as HTMLDivElement

    new window.Swiper(swiper, {
      effect: 'fade',
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 16,
      speed: 500,
      watchSlidesProgress: true,
      allowTouchMove: false,
      autoplay: {
        delay: 1000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
    }) as Swiper
  })
}

const createProcessSlider = (): void => {
  const slider = document.querySelector(
    '*[data-slider="process"]'
  ) as HTMLDivElement

  if (!slider) return

  const swiper = slider.querySelector('.swiper') as HTMLDivElement

  new window.Swiper(swiper, {
    slidesPerView: 1.2,
    slidesPerGroup: 1,
    spaceBetween: 16,
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      [media.lg]: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      [media.xl]: {
        slidesPerView: 4,
        spaceBetween: 28,
      },
    },
  }) as Swiper
}

export const createObjectSlider = (): void => {
  const sliderBullets = document.querySelector(
    '*[data-slider="object-bullets"]'
  ) as HTMLDivElement

  if (!sliderBullets) return

  const swiperBullets = sliderBullets.querySelector('.swiper') as HTMLDivElement

  const objectBullets = new window.Swiper(swiperBullets, {
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 12,
    speed: 500,
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.md]: {
        slidesPerView: 4.5,
      },
    },
  }) as Swiper

  const slider = document.querySelector(
    '*[data-slider="object"]'
  ) as HTMLDivElement
  const swiper = slider.querySelector('.swiper') as HTMLDivElement

  new window.Swiper(swiper, {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 24,
    speed: 500,
    grabCursor: true,
    watchSlidesProgress: true,
    thumbs: {
      swiper: objectBullets,
    },
  }) as Swiper
}

export const createQuizSlider = (): void => {
  const slider = document.querySelector(
    '*[data-slider="quiz"]'
  ) as HTMLDivElement

  if (!slider) return

  const swiper = slider.querySelector('.swiper') as HTMLDivElement
  const prev = slider.querySelector('.swiper-button-prev') as HTMLButtonElement
  const next = slider.querySelector('.swiper-button-next') as HTMLButtonElement

  new window.Swiper(swiper, {
    navigation: {
      prevEl: prev,
      nextEl: next,
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 24,
    allowTouchMove: false,
    watchSlidesProgress: true,
    autoHeight: true,
    on: {
      slideChange: (swiper: Swiper): void => {
        const quiz = swiper.el.closest('[data-quiz]') as HTMLElement
        const visibleSlide = swiper.visibleSlides[0] as HTMLDivElement

        checkQuizSlide(visibleSlide)

        visibleSlide === swiper.slides[swiper.slides.length - 1]
          ? quiz.setAttribute('data-quiz-end', '')
          : quiz.removeAttribute('data-quiz-end')
      },
    },
  }) as Swiper
}

export default (): void => {
  createCompanySlider()
  createProjectSlider()
  createProcessSlider()
}
